import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getData } from '../../helper';
import PatientForm from '../PatientForm';
import ProviderForm from '../ProviderForm';

function FormSelection({ API }) {
  const { inviteId } = useParams();
  const [ userRole, setUserRole ] = useState('');
  const [ userName, setUserName ] = useState('');
  const [ userMobile, setUserMobile ] = useState('');
  const [ userEmail, setUserEmail ] = useState('');

  useEffect(() => {
    getData(`${API}/invite/${inviteId}`)
      .then(message => {
        const { role, name, mobile, email } = message;
        setUserRole(role);
        setUserName(name);
        setUserMobile(mobile);
        setUserEmail(email);
      });
  }, [ inviteId, userRole, userName, userMobile, userEmail ]);

  let props = { API, userName, userMobile, userEmail };
  let RenderedForm = {
    patient: <PatientForm {...props} />,
    provider: <ProviderForm {...props} />,
  };

  return (<>{ RenderedForm[userRole] }</>);
}

export default FormSelection;
