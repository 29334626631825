import React from 'react';
import './style.scss';

import ImgSuccess from '../../assets/img_success.jpg';
import ImgFailure from '../../assets/img_failure.jpg';

function SuccessFailure({ status='success' }) {
  const textSet = {
    success: {
      title: 'THANK YOU!',
      text: [
        'You account has been successfully created.',
        'If you have any questions, please reach out to: hello@advancevitals.com',
      ],
      img: {
        url: ImgSuccess,
        alt: 'successfully submited',
      },
    },
    failure: {
      title: 'OOPS!',
      text: [
        `We're unable to create your account.`,
        'Please try again or reach out to: hello@advancevitals.com',
      ],
      img: {
        url: ImgFailure,
        alt: 'unable to submit',
      },
    },
  };
  const TextComp = textSet[status].text.map((text, idx) => (
    <p className="success-failure-text" key={`success-failure-text-${idx}`}>
      {text}
    </p>
  ));
  return (
    <section className="success-failure-container">
      <h2 className="success-failure-title">
        { textSet[status].title }
      </h2>
      { TextComp }
      <img
        className="success-failure-img"
        src={textSet[status].img.url}
        alt={textSet[status].img.alt}
      />
    </section>
  );
}

export default SuccessFailure;
