import React, { useState } from 'react';
import './style.scss';

function ProcessingOverlay() {
  return (
    <div className="processing-overlay-container">
      <div className="processing-overlay" />
      <div className="processing-text-wrapper">
        <p className="processing-text">
          Your information is being submitted...
        </p>
      </div>
      <div className="processing-overlay" />
    </div>
  );
}

export default ProcessingOverlay;
