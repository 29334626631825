import React from 'react';
import { Routes, Route } from "react-router-dom";
import FormSelection from './pages/FormSelection';
import SuccessFailure from './pages/SuccessFailure';
import './style/reset.scss';
import './style/style.scss';

const API = 'https://api.advancevitals.com';

export function App() {
  const formProps = { API };
  return (
    <div className="advance-vital-app">
      <Routes>
        <Route exact path="/"
          element={<FormSelection API />}
        />
        <Route exact path="/:inviteId"
          element={<FormSelection {...formProps} />}
        />
        <Route exact path="/success"
          element={<SuccessFailure status="success" />}
        />
        <Route exact path="/error"
          element={<SuccessFailure status="failure" />}
        />
      </Routes>
    </div>
  );
}
