export const formatDate = (isoDateString) => {
  const messageTimeStamp = new Date(isoDateString).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute:'2-digit',
  });
  let tempMessageTimeStamp = messageTimeStamp.split('/');
  if (tempMessageTimeStamp[0].length === 1) {
    tempMessageTimeStamp[0] = `0${tempMessageTimeStamp[0]}`
  }
  if (tempMessageTimeStamp[1].length === 1) {
    tempMessageTimeStamp[1] = `0${tempMessageTimeStamp[1]}`
  }
  const formatedMessageTimeStamp = tempMessageTimeStamp.join('/');
  return formatedMessageTimeStamp;
};

export const formatAddress = (
  streetAddressOne,
  streetAddressTwo,
  city,
  state,
  zipCode
) => {
  const formatedAddress = `
    ${streetAddressOne}${
      streetAddressTwo === '' ?
      ', ' :
      ' '.concat(streetAddressTwo.concat(', '))
    }
    ${city},
    ${state},
    ${zipCode}
  `;
  return formatedAddress;
};

export const postData = async (url='', data={}, h={}) => {
  const body = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'advancevitals.com',
    'Access-Control-Allow-Headers': '*',
  }, h);
  const response = await fetch(url, {
    headers,
    body,
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });
  return response.json();
};

export const getData = async(url='', h={}) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'advancevitals.com',
    'Access-Control-Allow-Headers': '*',
  }, h);
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers,
  });
  return response.json();
};

export const putData = async(url='', data={}, h={}) => {
  let body = null;
  let headers = Object.assign(h, {
    'Access-Control-Allow-Origin': 'advancevitals.com',
    'Access-Control-Allow-Headers': '*',
  });
  if (!headers['Content-Type']) headers['Content-Type'] = 'application/json';
  if (headers['Content-Type'] === 'application/json') body = JSON.stringify(data);
  if (headers['Content-Type'] === 'multipart/form-data') body = data;
  const response = await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers,
    body,
  });
  return response.json();
};

export const deleteData = async(url='', h={}) => {
  let headers = Object.assign(h, {
    'Access-Control-Allow-Origin': 'advancevitals.com',
    'Access-Control-Allow-Headers': '*',
  });
  if (!headers['Content-Type']) headers['Content-Type'] = 'application/json';
  const response = await fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers,
  });
  return response.json();
};
