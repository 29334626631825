import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import ProcessingOverlay from '../../components/ProcessingOverlay';
import InputField from '../../components/InputField';
import { postData } from '../../helper';
import './style.scss';

function ProviderForm({ API, userName, userMobile, userEmail }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ mobile, setMobile ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ dobMonth, setDobMonth ] = useState('');
  const [ dobDay, setDobDay ] = useState('');
  const [ dobYear, setDobYear ] = useState('');
  const [ streetAddressOne, setStreetAddressOne ] = useState('');
  const [ streetAddressTwo, setStreetAddressTwo ] = useState('');
  const [ city, setCity ] = useState('');
  const [ state, setState ] = useState('');
  const [ zipCode, setZipCode ] = useState('');
  const [ npi, setNpi ] = useState('');
  const [ inputErrors, setInputErrors ] = useState([]);
  const [ formSubmitted, setFormSubmitted ] = useState(false);

  /*********** prefill ***********/
  useEffect(() => {
    const [ userFirstName, userLastName ] = userName.split(' ');
    setFirstName(userFirstName);
    setLastName(userLastName);
    setMobile(userMobile);
    setEmail(userEmail);
  }, [ userName, userMobile, userEmail ]);

  const submitProviderForm = () => {
    let errors = [];
    let userData = { firstName, lastName, mobile, email, dobMonth, dobDay, dobYear, streetAddressOne, streetAddressTwo, city, state, zipCode, npi };
    const inviteId = location.pathname.substring(1, location.pathname.length);
    Object.keys(userData).forEach(d => {
      if (userData[d] === '' && d !== 'streetAddressTwo') {
        errors.push(d);
      }
    });
    if (errors.length !== 0) {
      setInputErrors(errors);
      return;
    }
    userData = Object.assign(userData, { role: 'provider' });
    if (formSubmitted === true) {
      return;
    }
    setFormSubmitted(true);
    postData(`${API}/user`, { inviteId, user: userData }).then(message => {
      console.log('message: ', message);
      if (message.status === 'ok') {
        navigate('/success');
        return;
      }
      navigate('/error');
    });
  };

  return (
    <>
      { formSubmitted === true && <ProcessingOverlay /> }
      <section className="provider-form-container">
        <div className="provider-form-wrapper">
          <h2 className="provider-form-title">Provider Signup Form</h2>
          <p className="provider-form-type">
            Contact Information
          </p>
          <div className="provider-form-input-wrapper">
            <div className="provider-form-multi-wrapper">
              <InputField
                name="first-name"
                label="First Name"
                placeholder="Cassandra"
                inputValue={firstName}
                hasError={inputErrors.indexOf('firstName') !== -1}
                errorMsg="Please enter a valid first name"
                onInputChange={e => setFirstName(e.target.value)}
              />
              <InputField
                name="last-name"
                label="Last Name"
                placeholder="Lopez"
                inputValue={lastName}
                hasError={inputErrors.indexOf('lastName') !== -1}
                errorMsg="Please enter a valid last name"
                onInputChange={e => setLastName(e.target.value)}
              />
            </div>
            <InputField
              name="mobile-phone-number"
              label="Mobile Phone Number"
              placeholder="9175130223"
              inputValue={mobile}
              inputType="text"
              hasError={inputErrors.indexOf('mobile') !== -1}
              errorMsg="Please enter a valid mobile phone number"
              onInputChange={e => {
                if (e.target.value.length > 11) return;
                setMobile(e.target.value);
              }}
            />
            <InputField
              name="email"
              label="Email"
              placeholder="caslopez@advancevitals.com"
              inputValue={email}
              hasError={inputErrors.indexOf('email') !== -1}
              errorMsg="Please enter a valid email"
              autoCap="off"
              onInputChange={e => setEmail(e.target.value)}
            />
          </div>
          <p className="provider-form-type">
            Date of Birth
          </p>
          <div className="provider-form-input-wrapper">
            <div className="provider-form-multi-wrapper">
            <InputField
                name="month"
                label="Month"
                placeholder="03"
                inputValue={dobMonth}
                inputType="number"
                hasError={inputErrors.indexOf('dobMonth') !== -1}
                errorMsg="Please enter a valid date"
                onInputChange={e => {
                  if (e.target.value.length > 2) return;
                  setDobMonth(e.target.value);
                }}
              />
              <InputField
                name="day"
                label="Day"
                placeholder="05"
                inputValue={dobDay}
                inputType="number"
                hasError={inputErrors.indexOf('dobDay') !== -1}
                errorMsg="Please enter a valid day"
                onInputChange={e => {
                  if (e.target.value.length > 2) return;
                  setDobDay(e.target.value);
                }}
              />
              <InputField
                name="year"
                label="Year"
                placeholder="1985"
                inputValue={dobYear}
                inputType="number"
                hasError={inputErrors.indexOf('dobYear') !== -1}
                errorMsg="Please enter a valid year"
                onInputChange={e => {
                  if (e.target.value.length > 4) return;
                  setDobYear(e.target.value);
                }}
              />
            </div>
          </div>
          <p className="provider-form-type">
            Address
          </p>
          <div className="provider-form-input-wrapper">
            <InputField
              name="street-address-one"
              label="Street Address 1"
              placeholder="3814 N Michigan Ave"
              inputValue={streetAddressOne}
              hasError={inputErrors.indexOf('streetAddressOne') !== -1}
              errorMsg="Please enter a valid street address"
              onInputChange={e => setStreetAddressOne(e.target.value)}
            />
            <InputField
              name="street-address-two"
              label="Street Address 2"
              placeholder="226"
              inputValue={streetAddressTwo}
              onInputChange={e => setStreetAddressTwo(e.target.value)}
            />
            <div className="patient-form-multi-wrapper">
              <InputField
                name="city"
                label="City"
                placeholder="Portland"
                inputValue={city}
                hasError={inputErrors.indexOf('city') !== -1}
              errorMsg="Please enter a valid city"
                onInputChange={e => setCity(e.target.value)}
              />
              <InputField
                name="state"
                label="State"
                placeholder="Oregon"
                inputValue={state}
                hasError={inputErrors.indexOf('state') !== -1}
                errorMsg="Please enter a valid state"
                onInputChange={e => setState(e.target.value)}
              />
              <InputField
                name="zipcode"
                label="Zip Code"
                placeholder="97227"
                inputValue={zipCode}
                hasError={inputErrors.indexOf('zipCode') !== -1}
                errorMsg="Please enter a valid zip code"
                onInputChange={e => {
                  if (e.target.value.length > 5) return;
                  setZipCode(e.target.value);
                }}
              />
            </div>
          </div>
          <p className="provider-form-type">
            Provider Information
          </p>
          <div className="provider-form-input-wrapper">
            <InputField
              name="npi"
              label="NPI"
              placeholder="0123456789"
              inputValue={npi}
              hasError={inputErrors.indexOf('npi') !== -1}
              errorMsg="Please enter a NPI"
              onInputChange={e => setNpi(e.target.value)}
            />
          </div>
          <button
            className="provider-form-submit-btn"
            onClick={submitProviderForm}
          >
            Submit
          </button>
        </div>
      </section>
    </>
  );
}

export default ProviderForm;
