import React from 'react';
import './style.scss';

function InputField({
  name = '',
  label = 'Input Label',
  inputType = 'text',
  placeholder = 'Input Label',
  inputValue = '',
  hasError = false,
  errorMsg = '',
  autoCap = 'on',
  onInputChange = () => {},
}) {
  return (
    <section className="form-input-container">
      <label
        className="form-input-label"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="form-input-wrapper">
        <input
          id={name}
          name={name}
          className="form-input"
          type={inputType}
          placeholder={placeholder}
          value={inputValue}
          autoCapitalize={autoCap}
          onChange={onInputChange}
        />
      </div>
      { hasError &&
        <p className="form-input-error">
          {errorMsg}
        </p>
      }
    </section>
  );
}

export default InputField;
